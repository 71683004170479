<template>
  <div class="panIcon">
        <div class="panning">
            <img src="@/assets/img/hand.png" />
        </div>
  </div>
</template>

<script>
export default {
 name: "load",
}
</script>

<style>

</style>