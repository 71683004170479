<template>
  <div class="appWrapper">
    <div
      class="sm-toggleMenu"
      @click="toggleFeatureLeft"
      :class="{ active: isActiveFeaturebox }"
      v-show="sm_toggleMenu"
      ref="sm_toggleMenu"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div class="sidePanel" ref="sidePanel">
      <ul>
        <li
          v-for="(featureItem, index) in this.selectedGradeExteriorFeatures"
          :key="index"
          :class="{
            'option activeFeature': featureItem.id == activeFeatureId,
          }"
          @click="getFeatures(featureItem, featureItem.id)"
        >
          <span>{{ featureItem.heading }}</span>
        </li>
      </ul>
      <div class="closePanelIco" @click="closeSidePanel">X</div>
    </div>

    <div class="area404" v-if="!this.modelName">
      <h1>404 Error</h1>
      <h4 class="title">Model Name Not Found in URL</h4>
    </div>

    <div v-if="this.modelName">
      <div class="gradient"></div>

      <div class="landingInner" v-if="this.vehicle">
        <div class="navWrapper fade-in-top" v-if="!isLoading">
          <!-- Categories ( Exterior & Interior Area ) -->
          <div
            class="mainOptionWrap"
            ref="mainOptionRef"
            id="mainOptionWrapper"
            v-if="exOptions"
          >
            <div
              class="mainOption"
              v-for="(category, index) in this.selectedGradeCategories"
               :key="index"

            >

              <div
                class="option"
                @click="getCategory(category, index)"
                :class="{ activeMain: index === activeOptionId }"

              >

                <div class="labelIn">{{ category }}</div>
                <span></span>
                <input type="text" hidden :mainCat="category"/>
              </div>
            </div>
          </div>


          <!-- // Categories ( Exterior & Interior Area ) -->

          <!-- Grade Lists -->

          <div class="gradeWalk" v-if="exOptions">
            <ul ref="gradeWalk" v-if="this.vehicleData.grades !== undefined">
              <li
                v-for="(item, index) in this.vehicleData.grades"
                :key="index"
                :class="{ activeWalk: index === gradeWalkId }"
                @click="getGradeData(item.grade_code, index, defColor, 0, $event)"
              >

                <span>{{ item.grade }} </span>
              </li>
            </ul>
          </div>
          <!-- // Grade Lists -->
        </div>
        <!-- navWrapper end -->

        <div ref="InExWrapper" class="InExWrapper" v-if="exOptions">
          <div
            class="Ex"
            v-if="!interiorMaster && threeSixtyImagePath"
            ref="Exee"
            :class="hotspotclicked ? 'zoomed' : ''"
          >
            <vue-three-sixty
              ref="_360_"
              :key="this.dataChanged"
              :amount="this.imageCount"
              :imagePath="this.threeSixtyImagePath"
              :fileName="this.threeSixtyFilename"
              :hotspots="this.exteriorHotspots"
              :setSpotCurrentImage="this.setSpotCurrentImage"
              @getHotWidget="fetchExteriorHot($event)"
              :openHotspotFormForFeatureId="this.openHotSpotFormImgFeatureId"
              :autoplay="this.playOnce"
              :autoplayOnce="this.playOnce"
              :ScrollImage="false"
              :ZoomIn="false"
              :disableScrolling="true"

            />
            <div class="colorName">
              <span> {{ colorName }} </span>
            </div>
          </div>

          <!-- Exterior Features List - Start -->

          <div
            class="featureBoxWrap Left aniDelay-2s"
            ref="featureBoxLeft"
            v-if="
              this.vehicleData.grades &&
              this.selectedGradeExteriorFeatures.length > 0
            "
            :class="{ toggleLeftOn: !toggleLeftFeatureBox }"
          >
            <div class="frameTop">
              <img src="@/assets/img/TopBorder.png" />
            </div>
            <ul>
              <!-- class="option activeFeature" -->
              <li
                :style="{ '--animation-order': index + 1 }"
                v-for="(
                  featureItem, index
                ) in this.selectedGradeExteriorFeatures.slice(
                  0,
                  this.selectedGradeExteriorFeatures.length / 2
                )"
                :key="index"
                :class="{
                  'option activeFeature': featureItem.id == activeFeatureId,
                }"
                @click="getFeatures(featureItem, featureItem.id)"
              >
                <span>{{ featureItem.heading }} </span>
              </li>
            </ul>
            <div class="frameBottom">
              <img src="@/assets/img/TopBorder.png" />
            </div>
          </div>

          <div
            class="featureBoxWrap Right feature-right-box aniDelay-2s"
            ref="featureBoxRight"
            v-if="
              this.selectedGradeExteriorFeatures.length >
              (this.selectedGradeExteriorFeatures.length / 2 ||
                !isLoadingFeature2)
            "
            :class="{ toggleRightOn: !toggleRightFeatureBox }"
          >
            <div class="frameTop">
              <img src="@/assets/img/TopBorder.png" />
            </div>
            <ul>
              <li
                :style="{ '--animation-order': index + 1 }"
                v-for="(
                  featureItem, index
                ) in this.selectedGradeExteriorFeatures.slice(
                  this.selectedGradeExteriorFeatures.length / 2,
                  this.selectedGradeExteriorFeatures.length
                )"
                :key="index"
                :class="{
                  'option activeFeature': featureItem.id == activeFeatureId,
                }"
                @click="getFeatures(featureItem, featureItem.id)"
              >
                <span>{{ featureItem.heading }}</span>
              </li>
            </ul>
            <div class="frameBottom">
              <img src="@/assets/img/TopBorder.png" />
            </div>
          </div>

          <!-- Exterior Features List - End -->

          <!-- Start - Exterior Widget (Pop Up Box) -->
          <div
            class="spotWrapper"
            ref="spotWrapper"
            @focusout="handleFocusOut"
            tabindex="0"
          >
            <div
              class="hotWidget"
              v-if="widgetActive"
              :key="this.filteredResultArr[0].id"
            >
              <div class="inner">
                <div class="outer" @click="hideSpotWrapper">
                  <div class="innerClose">
                    <label>Back</label>
                  </div>
                </div>
                <div class="videoFrame hotSection">
                  <video
                    width="100%"
                    id="vehicleVideo"
                    :poster="
                      this.$apibaseurl + this.filteredResultArr[0].thumbnail
                    "
                    controls
                    ref="video"
                  >
                    <source
                      :src="this.$apibaseurl + this.filteredResultArr[0].video"
                      type="video/mp4"
                    />
                  </video>
                  <div class="play-button-wrapper">
                    <div
                      title="Play video"
                      class="play-gif"
                      id="circle-play-b"
                      @click="playVideoClick"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 80 80"
                      >
                        <path
                          d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hotSection descWrap">
                <div class="head">
                  {{ this.filteredResultArr[0].heading }}
                </div>
                <div class="desc">{{ this.filteredResultArr[0].text }}</div>
              </div>
            </div>
          </div>
          <!-- End - Exterior Widget (Pop Up Box) -->

          <Loader v-if="this.divLoaded" />
        </div>

        <!-- Exterior Image Colours List -->


        <CarouselColor
          :CarouselColor="this.imageColourList"
          @getColourCode="changeExteriorCarColour($event)"
          ref="CarouselColor"


        />
        <!-- Exterior Image Colours List -->

        <!-- interior start -->

        <div class="interiorMasterWrapper" v-if="interiorMaster">
          <div class="interiorNav">
            <ul>
              <li
                ref="panoMenu"
                :class="{ activeInPano: index == activeIntId }"
                v-for="(category, index) in this.selectedGradeCategories"
                :key="index"
                @click="getIntRoute(category, index)"
              >
                {{ category }}

                <input type="text" hidden :mainCatInterior="category"/>

              </li>
              <li @click="gradeIn = !gradeIn">Choose Grade</li>
              <!-- <li class="active">Interior</li> -->
            </ul>
            <div class="GradeSm" @click="gradeIn = !gradeIn">
              <span>Choose Grade <i>&#8250;</i></span>
            </div>

            <div class="gradeIn" v-if="gradeIn">
              <div
                class="opt"
                v-for="(item, index) in this.vehicleData.grades"
                :key="index"
                :class="{ activeGrade: index === gradeWalkId }"
                @click="getGradeData(item.grade_code, index,defColor, 1)"
              >
                <div>{{ item.grade }} </div>
              </div>
            </div>
          </div>
          <div class="In">
            <PanoramaView
              :vehicleModel="this.vehicleData.model"
              :vehicleData="this.interiorData"
              :interiorHotspots="this.interiorHotspots"
              :windowWidth="this.windowWidth"
              :key="this.dataChanged"
              :openHotspotFeatureBox="this.openHotSpotFormImgFeatureId"
              :gradeForInterior="this.adobeGradecode"
              :colorToInterior="this.defColor"
            />
          </div>
        </div>

        <!-- interior end -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Logo from "../components/logoNissan.vue";
import adobeAnalytics from "../plugin/analytics";

import Loader from "../components/loader.vue";

import CarouselColor from "../components/vueCarousel.vue";

import "vue-360/dist/css/style.css";

export default {
  name: "LandingView",
  props: {
    action: Boolean,
  },
  components: {
    PanoramaView: () => import("../components/interiorPanorama.vue"),
    Logo,
    Loader,
    CarouselColor,
  },

  data() {
    return {
      sidePanel: false,
      playOnce: false,
      colorName: null,
      exOptions: true,
      gradeIn: false,
      divLoaded: false,
      isLoadingFeature: true,
      isLoadingFeature2: true,
      isLoading: true,
      modelName: null,
      vehicle: {},

      // adobe
      catgoryTypo: '',
      adobeGradecode: null,
      modelCode: "",
      defColor: null,
      categoryExInt: null,


      openHotSpotFormImgFeatureId: 0,

      toggleLeftFeatureBox: true,
      isActiveFeaturebox: true,
      toggleClicked: false,
      toggleRightFeatureBox: true,
      sm_toggleMenu: true,

      imageCount: 18,
      threeSixtyImagePath: null,
      threeSixtyFilename: "car_{index}.png",

      widgetActive: false,
      isWidgetHotActive: false,

      activeOptionId: 0,
      gradeWalkId: 0,
      activeGradeId: 0,
      activeFeatureId: 0,
      activeIntId: 1,
      isActiveMain: false,
      mainOptionRef: false,
      windowWidth: null,
      mobMode: false,
      isActiveColor: null,

      exteriorHotspots: [
        {
          id: null,
          frame: null,
          heading: null,
          text: null,
          x: null,
          y: null,
          video: null,
          thumbnail: null,
          cat: null
        },
      ],

      interiorHotspots: [
        {
          pitch: null,
          yaw: null,
          type: "info",
          heading: null,
          text: null,
          video: null,
          thumbnail: null,
        },
      ],

      clickedHotspot: [
        {
          id: null,
          frame: null,
          heading: null,
          text: null,
          x: null,
          y: null,
          video: null,
          thumbnail: null,
        },
      ],

      hotspotclicked: false,
      filteredResultArr: [],

      exteriorFeatures: true,
      interiorFeatures: false,

      spotWrapper: false,

      interiorMaster: false,

      vehicleData: {
        grades: null,
        grade_data: {},
        model: null,
        category: null,
        categoryImageCount: null,
        interiorData: {},
      },
      dataChanged: null,
      setSpotCurrentImage: 0,
      widgetActive: false,
      hotSpotActiveId: "",
      colorsTray: true,
      imageColourList: {},

      selectedGrade: "",
      selectedGradeCategories: [],
      selectedGradeExteriorFeatures: [],
      adobeFirstLoad: false,

    };
  },
  mounted: function () {
    this.$nextTick(function () {
      if (this.modelName) {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);

        this.isLoadingFeature2 = false;
        this.isLoadingFeature = false;
      }

      window.addEventListener("load", () => {
        this.windowWidth = window.innerWidth;
      });

      if (this.windowWidth < 991) {
        this.mobMode = true;
      }

      const targetDiv = this.$refs._360_;

      setTimeout(() => {
        if (this.imageCount > 20) this.divLoaded = true;
      }, 7000);
    });

    this.$el.addEventListener("click", this.onClick);

  },
  updated: function () {},
  created() {

    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    this.modelName = params.get("model");
    if (this.modelName) {
      this.getVehicleModelData();
    }


  },
  computed:{
  },
  methods: {
    toggleFeatureLeft() {
      // this.toggleLeftFeatureBox = !this.toggleLeftFeatureBox;
      // this.toggleRightFeatureBox = !this.toggleRightFeatureBox;
      // this.isActiveFeaturebox = !this.isActiveFeaturebox;

      this.$refs.sidePanel.classList.add("show");
    },

    closeSidePanel() {
      this.$refs.sidePanel.classList.remove("show");
      this.$refs.sidePanel.classList.add("hidePanel");
    },

    getIntRoute(cat, i) {
      if (i == 0) this.activeOptionId = i;

      if (cat == "Exterior") {
        this.interiorMaster = false;
        this.colorsTray = true;
        this.exOptions = true;

        this.sm_toggleMenu = true;

        this.categoryExInt == cat;


      }else{
        this.categoryExInt == "Interior"
      }



      let event_happened = this.colorName;
      let vehicle_name = this.modelName;
      let vehicleid = this.catgoryTypo;
      let vehicle_catgory = this.categoryExInt;
      let modelCode = this.modelCode;

      let event_126 = "";
      let events = 'event26';

      let all = this.modelName + "|" + vehicle_catgory + "|" + this.adobeGradecode

      //console.log(vehicle_name,"vehicle_name");

      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        all
      );





    },
    handleFocusOut() {
      // this.$refs.exToggleMenu.checked = false;
      // this.$refs.featuresOptionnRef.classList.remove("openPanel");
      // this.widgetActive = false;
      // this.$refs.InExWrapper.classList.remove("widgetHotActive");
      // this.$refs.Exee.classList.remove("zoomed");
    },
    onClick() {
      if ((this.divLoaded = true)) {
        this.divLoaded = false;

      }
    },
    openFeatures() {
      if (this.$refs.featuresOptionnRef.classList.contains("openPanel")) {
        this.$refs.featuresOptionnRef.classList.remove("openPanel");
      } else {
        this.$refs.featuresOptionnRef.classList.add("openPanel");
        //this.$refs.featuresOptionnRef.classList.remove("hidePanel");
      }
    },
    playVideoClick() {
      const video = document.getElementById("vehicleVideo");
      if (video.paused || video.ended) {
        video.play();
      } else {
        video.pause();
      }

      const circlePlayButton = document.getElementById("circle-play-b");

      video.addEventListener("playing", function () {
        circlePlayButton.style.opacity = 0;
      });
      video.addEventListener("pause", function () {
        circlePlayButton.style.opacity = 1;
      });
    },
    hideSpotWrapper() {
      // this.hotSpotActiveId = '';
      this.widgetActive = false;
      this.$refs.Exee.classList.remove("zoomed");
      this.$refs.InExWrapper.classList.remove("widgetHotActive");
    },
    changeExteriorCarColour(colourCode, index) {
      //console.log(colourCode,'clicked Color')
      // console.log(this.vehicle.data,'clicked data')
      //this.defColor = "";

      this.defColor = colourCode;


      this.openHotSpotFormImgFeatureId = 0;

      this.isActiveColor = index;

      this.vehicleData.categoryImageCount =
        this.selectedGrade.categories[0].category_images.filter((element) => {
          return element.colour_code == colourCode;
        }).length;
      // console.log(this.vehicleData.categoryImageCount,'clicked COUNT');

      var vehicleImagePath =
        this.selectedGrade.categories[0].category_images.filter((element) => {
          return element.colour_code == colourCode;
        })[0].image_path;

      this.threeSixtyImagePath = this.$apibaseurl + vehicleImagePath;
      this.imageCount = this.vehicleData.categoryImageCount;

      this.dataChanged = Math.floor(Math.random() * 10) + 1;

      this.colorName = colourCode.replace(/-/g, " ");


      let event_happened = this.colorName;
      let vehicle_name = this.modelName;
      let vehicleid = this.catgoryTypo;
      let vehicle_catgory = this.categoryExInt;
      let modelCode = this.modelCode;

      let event_126 = "";
      let events = 'event26';

      let all = this.modelName + "|" + vehicle_catgory + "|" + this.adobeGradecode + "|"  + event_happened

      //console.log(vehicle_name,"vehicle_name");

      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        all
      );




    },
    getVehicleModelData() {
      axios
        .get(this.$apibaseurl + "api/model/" + this.modelName)
        .then((result) => {
          this.vehicle = result.data;

          this.modelCode = result.data.vehicle_model.code ;

          this.vehicleData.grades = result.data.grades;



          this.getGradeData(
            this.vehicleData.grades[0].grade_code,
            0,
            this.vehicleData.grades[0].colour_code,
            0
          );

          this.adobeFirstLoad = true

        });
    },
    getGradeData(gradeCode, id, color, extOrInt ) {




      this.adobeGradecode = gradeCode

      this.gradeWalkId = id;

      this.openHotSpotFormImgFeatureId = 0;

      // const defColor = this.vehicleData.grades[0]
      // console.log(defColor,"defColor");
      this.colorName = color;

      /**
       * When Grade is changing, that time, you need to change these below datas:
       * change Categories.
       * change Images
       * change Colours
       * change Features
       */

      //removing active color class when change grade

      this.$refs.CarouselColor.setValue(0);

      const selectedGrade = this.vehicleData.grades.filter((value) => {
        return value.grade_code == gradeCode;
      });

      //console.log(selectedGrade, '1 selected Grade');

      this.selectedGrade = selectedGrade[0];



       this.categoryExInt = this.selectedGrade.categories[extOrInt].name;

        // if(this.selectedGrade.categories[id] !){

        // }




      // Lets change the Categories
      this.selectedGradeCategories = [];
      this.selectedGrade.categories.map((cateItem) => {
         //console.log(cateItem.name, 'items');
        this.selectedGradeCategories.push(cateItem.name);



      });
      // -- Lets change the Categories

      this.imageColourList =
        this.selectedGrade.categories[0].category_image_colours;
      // console.log(this.imageColourList, 'img colour lists')

      this.colorName = ""
      this.colorName = this.imageColourList[0].colour_code;

      this.vehicleData.categoryImageCount =
        this.selectedGrade.categories[0].category_images.filter((element) => {
          return element.colour_code == this.imageColourList[0].colour_code;
        }).length;
      // console.log(this.vehicleData.categoryImageCount, 'img count');

      var vehicleImagePath =
        this.selectedGrade.categories[0].category_images.filter((element) => {
          return element.colour_code == this.imageColourList[0].colour_code;
        })[0].image_path;

      this.threeSixtyImagePath = this.$apibaseurl + vehicleImagePath;
      //console.log(this.threeSixtyImagePath, 'image path');
      this.imageCount = this.vehicleData.categoryImageCount;

      var exteriorCategoryFeatures =
        this.selectedGrade.categories[0].category_image_features;
      this.selectedGradeExteriorFeatures = exteriorCategoryFeatures;
      // console.log(exteriorCategoryFeatures, 'exteriorCategoryFeatures')

      this.dataChanged = Math.floor(Math.random() * 10) + 1;

      var myex = this.selectedGrade.categories[0].name;

      this.exteriorHotspots = [];
      exteriorCategoryFeatures.map((item) => {
        this.exteriorHotspots.push({
          id: item.id,
          frame: item.category_image_no,
          heading: item.heading,
          text: item.description,
          x: item.spot_x,
          y: item.spot_y,
          video: item.video,
          thumbnail: item.video_poster,
          cat: myex
        });
      });

      // -- Exterior Data

      // Start - Interior Feature
      this.interiorData = this.selectedGrade.categories[1];

      var interiorCategoryFeatures =
        this.selectedGrade.categories[1].category_image_features;

      this.interiorHotspots = [];
      interiorCategoryFeatures.map((item) => {
        this.interiorHotspots.push({
          id: item.id,
          pitch: item.spot_x,
          yaw: item.spot_y,
          type: "info",
          text: item.heading,
          heading: item.heading,
          description: item.description,
          video: this.$apibaseurl + item.video,
          thumbnail: this.$apibaseurl + item.video_poster,
          clickHandlerFunc: this.hotSpotModalFunc,
          clickHandlerArgs: item.id,
        });
      });
      // End - Interior Feature







      let event_happened = this.adobeGradecode
      let vehicle_name = this.modelName;
      let vehicle_trigger = this.adobeGradecode ;
      let vehicleid = this.modelCode;
      let vehiclecatgory = this.categoryExInt;
      let intialColor = this.vehicleData.grades[0].colour_code

      let event_126 = '';
      let events = 'event26';

      let all = this.modelName + "|" + vehiclecatgory + "|"  + event_happened

      //console.log(vehicle_name,"vehicle_name");

      if(this.adobeFirstLoad){
      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        all
      );

      }







    },
    hotSpotModalFunc(data, id) {
      //Interior code
      // console.log(data, 'clickHandlerFunc of Interior')
      // console.log(id, 'clickHandlerArgs of Interior')
      this.$emit("hotspotClickOpenModal", id);
    },
    getCategory(type, id) {


      this.catgoryTypo = type;
      this.activeOptionId = id;

      if ((this.hotspotclicked = true))
        this.hotspotclicked = !this.hotspotclicked;

      if ((this.widgetActive = true)) {
        this.widgetActive = !this.widgetActive;
      }

      if (type == "Interior") {
        //   this.$refs.extmenuToggle.classList.add("hideBurger");
        //   this.colorsTray = false;
        //   this.interiorMaster = true;
        //   this.interiorFeatures = true;
        //   this.exteriorFeatures = false;

        //   if ((this.widgetActive = true)) {
        //     this.widgetActive = !this.widgetActive;
        //   }
        // } else {
        //   this.$refs.exToggleMenu.checked = false;

        //   let element = document.getElementById("featuresOption");

        //   setTimeout(() => {
        //     if (element.classList.contains("openPanel")) {
        //       this.$refs.featuresOptionnRef.classList.remove("openPanel");
        //     }
        //   }, 0);

        //   this.$refs.extmenuToggle.classList.remove("hideBurger");
        //   this.colorsTray = true;
        //   this.interiorMaster = false;
        //   this.interiorFeatures = false;
        //   this.exteriorFeatures = true;
        // }

        this.interiorMaster = true;
        this.exOptions = false;

        this.sm_toggleMenu = false;
      }else{
        this.categoryExInt = "Exterior"
      }


      let vehicleid = this.catgoryTypo;
      let event_happened = "category: " + vehicleid;
      let vehicle_name = this.modelName;
      let myGradeCode = this.adobeGradecode;

      let all = this.modelName + "|" + vehicleid +  "|"  + myGradeCode


      //let vehicle_catgory = this.catgoryTypo;
      let modelCode = this.modelCode;

      let event_126 = ""
      let events = 'event26';

      //console.log(vehicle_name,"vehicle_name");

      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        modelCode,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        all
      );


    },
    getFeatures(featureData, id) {

      let myGradeCode = this.adobeGradecode;
      //this.categoryExInt = featureData.name



      this.playOnce = false;
      this.activeOptionId = id;

      this.activeFeatureId = id;
      this.setSpotCurrentImage = parseInt(featureData.category_image_no);

      // console.log('ex hotspot feature id landing',featureData.id);
      this.openHotSpotFormImgFeatureId = parseInt(featureData.id);

      this.dataChanged = featureData.category_image_no;

      this.hotSpotActiveId = featureData.id;

      this.isWidgetHotActive = false;

      this.$refs.InExWrapper.classList.remove("widgetHotActive");

      // this.widgetActive = true
      if ((this.widgetActive = true)) {
        this.widgetActive = !this.widgetActive;
      }
      this.hotspotclicked = false;

      // if (
      //   featureData &&
      //   this.$refs.featuresOptionnRef.classList.contains("openPanel")
      // ) {
      //   this.$refs.exToggleMenu.checked = false;
      //   this.$refs.featuresOptionnRef.classList.remove("openPanel");
      // }



      let modelCode = this.modelCode;

      let defColor = this.defColor
      let vehicle_name = this.modelName;
      let vehiclecatgory = this.categoryExInt;
      //let vehicle_catgory = this.categoryExInt
      let grade_code =  myGradeCode

      let event_happened = "feature-heading: " + featureData.heading;

      let all = "";

      all = this.modelName +  "|" +  vehiclecatgory + "|"  + grade_code + "|" + featureData.heading + "|" +  defColor


      let event_126 = "";
      let events = 'event26';


      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        modelCode,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        all
      );




    },
    showWidget() {
      this.widgetActive = true;
    },
    hideWidget() {
      this.widgetActive = false;
    },
    fetchExteriorHot(id) {
      this.$refs.InExWrapper.classList.add("widgetHotActive");

      // this.hotSpotActiveId = id;
      this.clickedHotspot = this.exteriorHotspots.filter((e) => {
        e.id == id;

        return this.clickedHotspot;
      });
      this.hotspotclicked = true;

      const checkId = id;

      // console.log(this.clickedHotspot,"this.clickedHotspot");

      const filteredResult = this.clickedHotspot.find((e) => e.id == checkId);

      this.filteredResultArr = [];

      this.filteredResultArr.push(filteredResult);

      console.log(this.filteredResultArr,"filteredResult");

      this.widgetActive = true;

      if ((this.widgetActive = true)) {
        this.$refs.Exee.classList.add("zoomed");
        this.isWidgetHotActive = true;

        if (this.windowWidth < 667) {
          setTimeout(() => {
            // this.$refs.mainOptionRef.classList.add("z-index_0");
            // this.$refs.featuresOptionnRef.classList.add("z-index_0");
            this.$refs.spotWrapper.classList.add("mobileSpot");
            this.$refs.sidePanel.classList.remove("show");
          }, 0);
        }
      }
      // else{
      //           this.$refs.mainOptionRef.classList.remove("z-index_0");
      //           this.$refs.featuresOptionnRef.classList.remove("z-index_0");
      //         }


      // let event_happened = "";
      // let vehicle_name = this.modelName;
      // let vehicleid = this.catgoryTypo;
      // //let vehicle_catgory = this.catgoryTypo;
      // let modelCode = this.modelCode;

      // let event_126 = "feature-heading: " + this.filteredResultArr[0].heading;
      // let events = 'event26';

      // //console.log(vehicle_name,"vehicle_name");

      // adobeAnalytics(
      //   "R",
      //   vehicle_name,
      //   "",
      //   vehicle_name,
      //   modelCode,
      //   "EN",
      //   event_happened,
      //   "",
      //   "",
      //   "",
      //   event_126,
      //   events
      // );

    },

    CloseportraitPopup() {
      this.mobMode = false;
    },
  },

  watch: {},
};
</script>

<style>
.btnModal {
  font-size: 20px;
  padding: 11px;
  color: #fff;
  z-index: 222;
  position: absolute;
  top: 5px;
  right: 11px;
}

.area404 {
  text-align: center;
  margin-top: 15%;
}

.area404 h1 {
  font-size: 70px;
  color: #fff;
}

.area404 .title {
  font-size: 26px;
  color: #fff;
  font-weight: 400;
  margin-top: 26px;
}

.d-none {
  display: none;
}

.tooltip {
  position: absolute;
}

.v360-viewport {
  background-color: transparent;
  position: relative;
}

.v360-image-container {
  height: auto;
  /* position: absolute; */
  top: auto;
  /* bottom: 0em; */
  width: 100%;
  /* max-width: 75%; */
  max-width: 61%;
  cursor: grabbing;
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

#v360-menu-btns {
  display: none;
}

#v360-menu-btns {
  display: none;
}

.feature-right-box {
  right: 31px;
}

/* .closeIcon {
  position: absolute;
  right: 5%;
  top: 0;
  color: #fff;
  font-size: 21px;
  cursor: pointer;
} */
.hideBurger {
  display: none !important;
}
@media (max-width: 667px) {
  .closeIcon {
    font-size: 25px;
  }
}
.widgetHotActive .spotWrapper .descWrap {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.widgetHotActive .spotWrapper .descWrap .desc {
  margin-top: 0.55em;
}
@media (max-height: 768px) {
  .panIcon {
    /* height: 33%; */
    height: 41%;
  }
}
</style>
