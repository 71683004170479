<template>
  <div id="app" >
  
    <LandingMain/>

  </div>
</template>

<script>
import '@/assets/base.css';
import '@/assets/scss/main.scss';
import LandingMain from './view/landingMain.vue'

export default {
  name: 'App',
  components: {
    LandingMain
  }
}
</script>

<style>
</style>
